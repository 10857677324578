var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-0",attrs:{"name":"orders"}},[_c('process-device-ids',{ref:"deviceDialog"}),_c('v-row',{ref:"toolbar",staticClass:"toolbar pa-4 light-blue lighten-2",attrs:{"no-gutters":"","justify":"start","align":"center"}},[_c('v-select',{staticClass:"order-patient-selector mr-2",staticStyle:{"max-width":"180px"},attrs:{"outlined":"","dense":"","background-color":"white","items":_vm.orgs,"item-value":"id","item-text":"name","placeholder":"All organizations","hide-details":"","clearable":"","append-icon":""},on:{"change":_vm.selectOrg}}),_c('v-btn-toggle',{attrs:{"mandatory":"","dense":"","borderless":"","color":"button"},on:{"change":_vm.getOrdersByStatus},model:{value:(_vm.orderStatus),callback:function ($$v) {_vm.orderStatus=$$v},expression:"orderStatus"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"width":"48","min-width":"48","height":"40","value":"ordered","disabled":_vm.hasSearch}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-text-box-outline")])],1)]}}])},[_c('span',[_vm._v("New Orders")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"width":"48","min-width":"48","height":"40","value":"registered","disabled":_vm.hasSearch}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-text-box-check-outline")])],1)]}}])},[_c('span',[_vm._v("Registered")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"width":"48","min-width":"48","height":"40","value":"shipped","disabled":_vm.hasSearch}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-truck-fast-outline")])],1)]}}])},[_c('span',[_vm._v("Shipped")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"width":"48","min-width":"48","height":"40","value":"delivered","disabled":_vm.hasSearch}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-truck-check-outline")])],1)]}}])},[_c('span',[_vm._v("Delivered")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"width":"48","min-width":"48","height":"40","value":"archived","disabled":_vm.hasSearch}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-archive")])],1)]}}])},[_c('span',[_vm._v("Archived")])])],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"flex-grow-1 px-12"},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-text-field',{staticClass:"search",attrs:{"placeholder":"Search by patient name...","height":"40","solo":"","flat":"","dense":"","hide-details":"","clearable":"","prepend-inner-icon":"mdi-magnify","color":"grey darken-1","background-color":"white lighten-2","loading":_vm.searchBusy},on:{"input":_vm.search,"click:clear":function($event){_vm.searchInput = ''}}})],1)],1)]),(_vm.orderStatus === _vm.STATUS.ORDERED)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"min-width":"40","width":"44","height":"40","small":"","color":"white","depressed":""},on:{"click":function($event){return _vm.$refs.deviceDialog.open()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-text-box-check-outline")])],1)]}}],null,false,452164896)},[_c('span',[_vm._v("Register Devices")])]):_vm._e(),(_vm.orderStatus === _vm.STATUS.REGISTERED)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.selectedItems.length === 0,"small":"","color":"white","min-width":"40","width":"44","depressed":"","height":"40"},on:{"click":function($event){return _vm.markAs('shipped')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-truck-fast-outline")])],1)]}}],null,false,2004769752)},[_c('span',[_vm._v("Mark as shipped")])]):_vm._e(),(_vm.orderStatus === _vm.STATUS.SHIPPED)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.selectedItems.length === 0,"small":"","color":"white","min-width":"40","width":"44","depressed":"","height":"40"},on:{"click":function($event){return _vm.markAs('delivered')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-truck-check-outline")])],1)]}}],null,false,2479436329)},[_c('span',[_vm._v("Mark as delivered")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"disabled":_vm.selectedItems.length === 0,"small":"","color":"white","min-width":"40","width":"44","depressed":"","height":"40"},on:{"click":function($event){return _vm.markAs('archived')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-archive")])],1)]}}])},[_c('span',[_vm._v("Archive")])]),_c('v-btn',{staticClass:"ml-2",attrs:{"min-width":"40","width":"44","height":"40","disabled":_vm.selectedItems.length === 0,"small":"","color":"success","depressed":""},on:{"click":_vm.download}},[_c('v-icon',{staticClass:"ml-n1"},[_vm._v("mdi-microsoft-excel")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.getOrders(_vm.orgId, _vm.orderStatus)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"ml-n1"},[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Reload")])])],1),_c('v-divider'),(_vm.busy)?_c('v-progress-circular',{staticClass:"center",attrs:{"size":50,"color":"grey lighten-2","indeterminate":""}}):_c('v-row',{staticClass:"px-5",staticStyle:{"margin-top":"72px"}},[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"fixed-header":true,"hide-default-footer":"","show-select":"","items":_vm.orders,"items-per-page":1000},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.archived)?_c('v-icon',[_vm._v("mdi-archive")]):(item.delivered)?_c('v-icon',[_vm._v("mdi-truck-check-outline")]):(item.shipped)?_c('v-icon',[_vm._v("mdi-truck-fast-outline")]):(item.registered)?_c('v-icon',[_vm._v("mdi-text-box-check-outline")]):_c('v-icon',[_vm._v("mdi-text-box-outline")])]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.orgsMap[item.owner.id]))]}},{key:"item.deviceName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.deviceName))]}},{key:"item.ordered",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.date(item.ordered)))]}},{key:"item.registered",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.date(item.registered)))]}},{key:"item.shipped",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.date(item.shipped)))]}},{key:"item.delivered",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.date(item.delivered)))]}},{key:"item.archived",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.date(item.archived)))]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }