<template>
  <v-col name="orders" class="pa-0">
    <process-device-ids ref="deviceDialog" />

    <v-row ref="toolbar" no-gutters class="toolbar pa-4 light-blue lighten-2" justify="start" align="center">
      <v-select
        outlined
        dense
        background-color="white"
        :items="orgs"
        item-value="id"
        item-text="name"
        placeholder="All organizations"
        hide-details
        class="order-patient-selector mr-2"
        style="max-width: 180px"
        clearable
        append-icon
        @change="selectOrg"
      ></v-select>
      <v-btn-toggle mandatory dense borderless color="button" v-model="orderStatus" @change="getOrdersByStatus">
        <!-- New Orders -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="48" min-width="48" height="40" value="ordered" v-bind="attrs" v-on="on" :disabled="hasSearch">
              <v-icon>mdi-text-box-outline</v-icon>
            </v-btn>
          </template>
          <span>New Orders</span>
        </v-tooltip>

        <!-- Registered -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              width="48"
              min-width="48"
              height="40"
              value="registered"
              v-bind="attrs"
              v-on="on"
              :disabled="hasSearch"
            >
              <v-icon>mdi-text-box-check-outline</v-icon>
            </v-btn>
          </template>
          <span>Registered</span>
        </v-tooltip>

        <!-- Shipped -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="48" min-width="48" height="40" value="shipped" v-bind="attrs" v-on="on" :disabled="hasSearch">
              <v-icon>mdi-truck-fast-outline</v-icon>
            </v-btn>
          </template>
          <span>Shipped</span>
        </v-tooltip>

        <!-- Delivered -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              width="48"
              min-width="48"
              height="40"
              value="delivered"
              v-bind="attrs"
              v-on="on"
              :disabled="hasSearch"
            >
              <v-icon>mdi-truck-check-outline</v-icon>
            </v-btn>
          </template>
          <span>Delivered</span>
        </v-tooltip>

        <!-- Archived -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              width="48"
              min-width="48"
              height="40"
              value="archived"
              v-bind="attrs"
              v-on="on"
              :disabled="hasSearch"
            >
              <v-icon>mdi-archive</v-icon>
            </v-btn>
          </template>
          <span>Archived</span>
        </v-tooltip>
      </v-btn-toggle>
      <v-row no-gutters align="center">
        <div class="flex-grow-1 px-12">
          <v-row no-gutters justify="center">
            <v-text-field
              class="search"
              placeholder="Search by patient name..."
              height="40"
              solo
              flat
              dense
              hide-details
              clearable
              prepend-inner-icon="mdi-magnify"
              color="grey darken-1"
              background-color="white lighten-2"
              :loading="searchBusy"
              @input="search"
              @click:clear="searchInput = ''"
            ></v-text-field>
          </v-row>
        </div>
      </v-row>

      <!-- Ordered Devices -->
      <v-tooltip v-if="orderStatus === STATUS.ORDERED" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            min-width="40"
            width="44"
            height="40"
            small
            color="white"
            depressed
            v-bind="attrs"
            v-on="on"
            @click="$refs.deviceDialog.open()"
          >
            <v-icon>mdi-text-box-check-outline</v-icon>
          </v-btn>
        </template>
        <span>Register Devices</span>
      </v-tooltip>

      <!-- Mark as Shipped -->
      <v-tooltip v-if="orderStatus === STATUS.REGISTERED" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="selectedItems.length === 0"
            small
            color="white"
            min-width="40"
            width="44"
            depressed
            height="40"
            v-bind="attrs"
            v-on="on"
            @click="markAs('shipped')"
          >
            <v-icon>mdi-truck-fast-outline</v-icon>
          </v-btn>
        </template>
        <span>Mark as shipped</span>
      </v-tooltip>
      <!-- Mark as Delivered  -->
      <v-tooltip v-if="orderStatus === STATUS.SHIPPED" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="selectedItems.length === 0"
            small
            color="white"
            min-width="40"
            width="44"
            depressed
            height="40"
            v-bind="attrs"
            v-on="on"
            @click="markAs('delivered')"
          >
            <v-icon>mdi-truck-check-outline</v-icon>
          </v-btn>
        </template>
        <span>Mark as delivered</span>
      </v-tooltip>

      <!-- Mark as Archived  -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="selectedItems.length === 0"
            class="ml-2"
            small
            color="white"
            min-width="40"
            width="44"
            depressed
            height="40"
            v-bind="attrs"
            v-on="on"
            @click="markAs('archived')"
          >
            <v-icon>mdi-archive</v-icon>
          </v-btn>
        </template>
        <span>Archive</span>
      </v-tooltip>

      <!-- Download -->
      <v-btn
        class="ml-2"
        min-width="40"
        width="44"
        height="40"
        :disabled="selectedItems.length === 0"
        small
        color="success"
        depressed
        @click="download"
      >
        <v-icon class="ml-n1">mdi-microsoft-excel</v-icon>
      </v-btn>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-3" icon color="white" @click="getOrders(orgId, orderStatus)" v-bind="attrs" v-on="on">
            <v-icon class="ml-n1">mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Reload</span>
      </v-tooltip>
    </v-row>
    <v-divider></v-divider>
    <v-progress-circular
      v-if="busy"
      class="center"
      :size="50"
      color="grey lighten-2"
      indeterminate
    ></v-progress-circular>
    <v-row v-else class="px-5" style="margin-top: 72px">
      <v-data-table
        :headers="headers"
        :fixed-header="true"
        hide-default-footer
        show-select
        :items="orders"
        class="elevation-0"
        :items-per-page="1000"
        style="width: 100%"
        v-model="selectedItems"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-icon v-if="item.archived">mdi-archive</v-icon>
          <v-icon v-else-if="item.delivered">mdi-truck-check-outline</v-icon>
          <v-icon v-else-if="item.shipped">mdi-truck-fast-outline</v-icon>
          <v-icon v-else-if="item.registered">mdi-text-box-check-outline</v-icon>
          <v-icon v-else>mdi-text-box-outline</v-icon>
        </template>
        <template v-slot:[`item.owner`]="{ item }">{{ orgsMap[item.owner.id] }}</template>
        <template v-slot:[`item.deviceName`]="{ item }">{{ item.deviceName }}</template>
        <template v-slot:[`item.ordered`]="{ item }">{{ date(item.ordered) }}</template>
        <template v-slot:[`item.registered`]="{ item }">{{ date(item.registered) }}</template>
        <template v-slot:[`item.shipped`]="{ item }">{{ date(item.shipped) }}</template>
        <template v-slot:[`item.delivered`]="{ item }">{{ date(item.delivered) }}</template>
        <template v-slot:[`item.archived`]="{ item }">{{ date(item.archived) }}</template>
      </v-data-table>
    </v-row>
  </v-col>
</template>

<style lang="scss">
[name='orders'] {
  .toolbar {
    position: fixed;
    width: 100%;
    z-index: 5;
  }

  .search {
    max-width: 600px;
  }
  .v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 1;
  }
  .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn--active .v-icon {
    color: white;
  }
  .order-patient-selector * {
    font-size: 13px !important;
    font-weight: 500;
    border: 0;
  }

  .v-input__slot fieldset {
    border-color: #dfdfdf !important;
  }
}
</style>

<script>
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import { STATUS } from '../consts'
import ProcessDeviceIds from '../components/ProcessDeviceIds.vue'
import { state as orderState, getOrders, searchOrders, updateOrders } from '../shared-ui/store/order'
import { state as orgState } from '../shared-ui/store/org'
import { strcmp } from '../helpers/str'
import { createObjectCsvStringifier } from 'csv-writer'
import debounce from 'lodash/debounce'

export default defineComponent({
  components: { ProcessDeviceIds },
  setup() {
    const busy = ref(false)
    const selectedItems = ref([])
    const searchBusy = ref(false)
    const searchInput = ref('')
    const orgId = ref('')
    const orderStatus = ref(STATUS.ORDERED)
    const orgs = computed(() => orgState.orgs.sort((a, b) => strcmp(a.name, b.name)))
    const orgsMap = computed(() => orgState.orgsMap || {})

    const showSelect = computed(() => {
      return !hasSearch.value
    })

    const hasSearch = computed(() => {
      return !!searchInput.value
    })

    const headers = computed(() => {
      let headers = [
        { text: '', value: 'status', width: 60 },
        { text: 'Owner', value: 'owner', width: 180 },
        { text: 'Name', value: 'shippingRecipientName', width: 240 },
        { text: 'Device', value: 'deviceName', width: 175 },
      ]
      switch (orderStatus.value) {
        case STATUS.ORDERED:
          headers.push({
            text: 'Ordered',
            value: 'ordered',
            width: 150,
            align: 'start',
          })
          break
        case STATUS.REGISTERED:
          headers.push({ text: 'Device ID', value: 'deviceId', width: 150 })
          headers.push({
            text: 'Registered',
            value: 'registered',
            width: 150,
            align: 'start',
          })
          break
        case STATUS.SHIPPED:
          headers.push({
            text: 'Shipped',
            value: 'shipped',
            width: 150,
            align: 'start',
          })
          break
        case STATUS.DELIVERED:
          headers.push({
            text: 'Delivered',
            value: 'delivered',
            width: 150,
            align: 'start',
          })
          break
        case STATUS.ARCHIVED:
          headers.push({
            text: 'Ordered',
            value: 'ordered',
            width: 150,
            align: 'start',
          })
          break
      }
      return headers
    })

    const date = value => {
      if (value) {
        return dayjs(value).calendar()
      }
      return ''
    }

    const selectOrg = id => {
      orgId.value = id
      getOrders(orgId.value, orderStatus.value)
    }

    const search = debounce(async text => {
      searchInput.value = text
      if (text) {
        searchBusy.value = true
        await searchOrders(text, orgId.value)
        searchBusy.value = false
      } else {
        getOrders(orgId.value, orderStatus.value)
      }
    }, 500, {trailing: true})

    const getOrdersByStatus = async status => {
      orderStatus.value = status
      busy.value = true
      selectedItems.value = []
      await getOrders(orgId.value, orderStatus.value)
      busy.value = false
    }

    const orders = computed(() => {
      const orders = Array.from(orderState.orders)
      orders.sort((a,b) => b.ordered.localeCompare(a.ordered))
      return orders
    })

    const markAs = async property => {
      selectedItems.value.forEach(item => {
        item[property] = new Date().toISOString()
      })
      await updateOrders(selectedItems.value)
      getOrders(orgId.value, orderStatus.value)
    }

    const download = () => {
      let statusLabel = dayjs(new Date()).format('YYYY-MM-DD') + '--' + orderStatus.value.toUpperCase()
      statusLabel = 'ORDERS-' + statusLabel

      const items = []
      selectedItems.value.forEach(order => {
        items.push({
          orderId: order.id || '',
          ptId: order.patientId || '',
          orgId: order.owner.id || '',
          orgName: orgsMap.value[order.owner.id] || '',
          device: order.deviceName || '', //deviceToString(order.items),
          orgPtId: order.ownerPatientId || '',
          recipientName: order.shippingRecipientName || '',
          line1: order.shippingAddress.line1 || '',
          line2: order.shippingAddress.line2 || '',
          city: order.shippingAddress.city || '',
          state: order.shippingAddress.state || '',
          zip: order.shippingAddress.zip || '',
          ordered: order.ordered || '',
        })
      })

      const w = createObjectCsvStringifier({header: [
        { id: 'orderId', title: 'Order ID' },
        { id: 'ptId', title: 'Patient ID' },
        { id: 'orgId', title: 'Org ID' },
        { id: 'orgName', title: 'Org Name' },
        { id: 'device', title: 'Device' },
        { id: 'orgPtId', title: 'Org Patient ID' },
        { id: 'recipientName', title: 'Recipient Name' },
        { id: 'line1', title: 'Addr Line 1' },
        { id: 'line2', title: 'Addr Line 2' },
        { id: 'city', title: 'Addr City' },
        { id: 'state', title: 'Addr State' },
        { id: 'zip', title: 'Addr Zip' },
        { id: 'ordered', title: 'Ordered' }
      ]})
      const csvStr = w.getHeaderString() + w.stringifyRecords(items)

      let exportedFilenmae = statusLabel + '.csv'
      let blob = new Blob([csvStr], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae)
      } else {
        let link = document.createElement('a')
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          let url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', exportedFilenmae)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    }

    onMounted(() => {
      getOrders(orgId.value, orderStatus.value)
    })

    return {
      busy,
      date,
      download,
      getOrders,
      getOrdersByStatus,
      hasSearch,
      headers,
      markAs,
      orders,
      orderStatus,
      orgId,
      orgs,
      orgsMap,
      search,
      searchBusy,
      searchInput,
      selectedItems,
      selectOrg,
      showSelect,
      STATUS,
    }
  },
})
</script>