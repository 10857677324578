<template>
  <v-dialog
    ref="dialog"
    v-model="showDialog"
    max-width="1024"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card class="d-flex flex-column" :class="{ fullscreen: $vuetify.breakpoint.smAndDown }">
      <v-card-title class="title pl-5 mb-3" align="center">
        <div class="pl-2">Register Devices</div>
        <v-spacer />
        <v-btn class="pa-2 mr-2" style="min-width: 0" color="primary" icon :disabled="busy" @click="clearContents">
          <v-icon>mdi-table-large-remove</v-icon>
        </v-btn>
        <v-btn
          :disabled="busy"
          v-shortkey="['meta', 'v']"
          @shortkey="pasteContents"
          class="pa-2"
          style="min-width: 0"
          color="primary"
          icon
          @click="pasteContents"
        >
          <v-icon>mdi-content-paste</v-icon>
        </v-btn>
        <v-divider vertical class="mx-2"></v-divider>
        <v-btn icon @click="close" :disabled="busy">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="d-flex flex-grow-1">
        <div style="overflow-x: auto" class="mx-n2 elevation-1">
          <div ref="sheetEl"></div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed class="primary px-6" @click="submit" :loading="busy">Register</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, nextTick } from '@vue/composition-api'
import { state as orgState } from '../shared-ui/store/org'
import clone from 'lodash/clone'
import { markDevicesAsRegistered } from '../shared-ui/store/order'

/* global jexcel */
export default {
  setup() {
    let sheet

    const busy = ref(false)
    const showDialog = ref(false)
    const sheetEl = ref(null)
    const sheetOptions = {
      tableOverflow: true,
      tableWidth: '2300px',
      minDimensions: [0, 15],
      allowDeleteColumn: false,
      allowManualInsertColumn: false,
      columns: [
        {
          title: 'Provider ID',
          width: '200',
          align: 'left',
        },
        {
          title: 'Provider',
          width: '200',
          align: 'left',
        },
        {
          title: 'Tracking Num',
          width: '100',
          align: 'left',
        },
        {
          title: 'Device ID',
          width: '150',
          align: 'left',
        },
        {
          title: 'Device',
          width: '100',
          align: 'left',
        },
        {
          title: 'PID',
          width: '200',
          align: 'left',
        },
        {
          title: 'Contact Name',
          width: '200',
          align: 'left',
        },
        {
          title: 'Company or Name',
          width: '100',
          align: 'left',
        },
        {
          title: 'Country',
          width: '100',
        },
        {
          title: 'Address1',
          width: '250',
          align: 'left',
        },
        {
          title: 'Address2',
          width: '150',
          align: 'left',
        },
        {
          title: 'Address3',
          width: '100',
          align: 'left',
        },
        {
          title: 'City',
          width: '140',
          align: 'left',
        },
        {
          title: 'State',
          width: '50',
        },
        {
          title: 'Postal Code',
          width: '100',
          align: 'left',
        },
        {
          title: 'Telephone',
          width: '100',
        },
      ],
    }

    const clearContents = () => {
      sheet.setData([[]], false)
    }

    const pasteContents = async () => {
      const orgsMap = orgState.orgsMap
      const colCount = sheetOptions.columns.length
      const text = await navigator.clipboard.readText()
      const rows = text.split('\n')
      rows.forEach((row, index) => {
        let cols = row.split('\t')
        if (!cols || !cols.length) {
          return
        }
        cols.splice(colCount - 1, cols.length - colCount)
        cols.splice(0, 0, orgsMap[cols[0]])
        rows[index] = cols
      })
      sheet.setData(rows, false)
    }

    const open = () => {
      showDialog.value = true
      nextTick(() => {
        let options = clone(sheetOptions)
        options.data = [[]]
        sheet = jexcel(sheetEl.value, options)
      })
    }

    const close = () => {
      sheet.destroy()
      showDialog.value = false
    }

    const submit = async () => {
      busy.value = true
      let data = sheet.getData()
      let payload = []
      data.forEach(items => {
        if (items.length && items[0]) {
          payload.push({
            ownerId: items[0],
            deviceId: items[3],
            deviceName: items[4],
            ownerPatientId: items[5],
          })
        }
      })
      await markDevicesAsRegistered(payload)
      busy.value = false
      close()
    }

    return {
      busy,
      clearContents,
      close,
      open,
      pasteContents,
      sheetEl,
      showDialog,
      submit,
    }
  },
}
</script>